
import { defineComponent, ref } from "vue";
import { useAuthStore } from "@/stores/auth/AuthStore";
import Alert from "@/misc/Alert";
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const authStore = useAuthStore();
    let file = ref(null);

    const uploadFile = () => {
      Alert.Loading();
      authStore
        .setBulkRegister({ archive_file: file.value })
        .then(() => {
          Alert.Success({
            title: "Bulk Register", 
            message: "Success Upload" 
          });
        })
        .catch((err) => {
          Alert.Error({
            title: "Bulk Register",
            message: err,
          });
        });
    };

    const getTemplate = () => {
      Alert.Loading();
      authStore
        .getBulkRegisterTemplate()
        .then((data) => {
          FileDownload(data.data, "bulk-register.csv");
          Alert.Close();
        })
        .catch((err) => {
          Alert.Error({
            title: "Register Template",
            message: err,
          });
        });
    };

    return {
      uploadFile,
      getTemplate,
      file,
    };
  },
});
